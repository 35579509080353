.card-column {
  max-width: 25%;
  width: 100%;
  padding: 40px 20px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 1023px) {
  .card-column {
    max-width: 100%;
    max-height: unset;
    height: unset;
  }
}
