.main-header {
  background-image: url("../../../public/images/directory-header.jpg");
  height: 120px;
  width: 100%;
  max-width: 100%;
}

.header-icon-div {
  padding: 30px;
  display: none;
}

.header-icon-div div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.header-icon {
  max-width: 60px;
  width: 100%;
  margin-right: 15px;
}

@media (max-width: 1023px) {
  .header-icon-div {
    display: block;
  }
}
