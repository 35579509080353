.name-search {
  width: 100%;
  position: relative;
  max-width: 100%;
}

.input-container {
  position: relative;
}

.search-bar {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 15px;
  box-sizing: border-box;
  max-width: 100%;
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 45%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: red;
  font-weight: bold;
}

.company-list {
  list-style-type: none;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1;
  margin-top: -10px;
}

.company-list li {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.company-list li:last-child {
  border-bottom: none;
}

.company-list li:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

/* @media (max-width: 1023px) {
  .name-search {
    max-width: 900px;
  }
} */
