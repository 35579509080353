.zip-search {
  margin-bottom: 25px;
}

.zip-input {
  max-width: 100px;
  width: 100%;
  border-radius: 5px;
  padding: 3px 0;
}

.zip-miles {
  max-width: 70px;
  width: 100%;
  border-radius: 5px;
  margin: 0 5px;
  padding: 3px 0;
}

.zip-btn {
  border-radius: 5px;
  padding: 5px 15px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}

@media (max-width: 1564px) {
  .zip-input {
    max-width: 70px;
  }
}
