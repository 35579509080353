body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.green-txt {
  color: #0f9a82;
}

.green-bg {
  background-color: #0f9a82;
}

.blue-txt {
  color: #003f53;
}

.blue-bg {
  background-color: #003f53;
}

.offwhite-bg {
  background-color: #f6f6f6;
}

.offwhite-txt {
  color: #f6f6f6;
}

.white-txt {
  color: #fff;
}

.white-bg {
  background-color: #fff;
}

.grey-txt {
  color: #c1c1c1;
}

.dark-txt {
  color: #000;
}
