.filter-column {
  max-width: 15%;
  width: 100%;
  padding: 40px 20px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.reset-btn-div {
  display: flex;
}

.reset-btn {
  border: 1px solid #003f53;
  color: #003f53;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  margin: 20px auto;
  cursor: pointer;
}

.apply-btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  margin: 20px auto 0;
  cursor: pointer;
  display: none;
  border: 1px solid #0f9a82;
}

@media (max-width: 1564px) {
  .filter-column {
    overflow-y: auto;
  }
}

@media (max-width: 1023px) {
  .filter-column-container {
    max-width: 100%;
    width: 100%;
    padding: 40px 20px;
  }
  .filter-column {
    max-width: 100%;
    height: unset;
    padding: 0;
  }

  .apply-btn {
    display: block;
  }
}
