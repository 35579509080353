.main-content-row {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: row;
  height: calc(100vh - 200px);
}

.show-filter-btn,
.apply-filters-btn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #003f53;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1000;
}

.mobile {
  display: none;
}

@media (max-width: 1023px) {
  .mobile {
    display: block;
  }
  .desk {
    display: none;
  }

  .main-content-row {
    height: unset;
  }
}
