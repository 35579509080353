.single-company-card {
  position: relative;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.results {
  text-align: center;
  margin: 0 auto;
}

.top-single-card,
.mid-single-card,
.bot-single-card {
  border-radius: 5px;
  padding: 10px 40px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.top-single-card {
  margin-top: 30px;
}

.card-split-div {
  display: flex;
  flex-wrap: wrap;
}

.card-single-split {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.card-single-split img {
  max-width: 20px;
  width: 100%;
  height: 20px;
  margin-right: 10px;
}

.email-img {
  max-width: 25px !important;
}

.serve-flex-div {
  display: flex;
  flex-wrap: wrap;
}

.serve-flex-div p {
  width: 50%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: red;
}

.sing-comp-btn {
  display: none;
}

@media (max-width: 1600px) {
  .card-split-div {
    flex-direction: column;
  }

  .card-single-split {
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .sing-comp-btn {
    display: block;
  }
}
