.result-column {
  padding: 40px 20px;
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 200px);
}

.no-results {
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 1023px) {
  .result-column {
    width: 100%;
    height: 100%;
  }
}
