.services-search {
  margin-bottom: 25px;
}

.services-search select {
  max-width: 235px;
  width: 100%;
  border-radius: 5px;
  padding: 3px 0;
}
