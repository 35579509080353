.small-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 80%;
  margin: 0 auto;
}

.small-card {
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
}

.small-card-header {
  background-image: url("../../../public/images/card-header.png");
  width: 100%;
  max-width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 70px;
  position: relative;
}

.card-person {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35px;
  margin: 0 auto;
}

.top-card-info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.info-split {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-split img {
  max-width: 20px;
  width: 100%;
  height: 20px;
  margin-right: 10px;
}

.inner-card-holder {
  padding: 40px;
}

.small-card-btn {
  border: none;
  padding: 7px 20px;
  border-radius: 5px;
  cursor: pointer;
}
